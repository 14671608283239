<template>
  <v-app>
    <Navigation v-if="!isAnonymous"/>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="6">
            <div class="d-flex">
              <v-icon light>mdi-help-circle</v-icon>
              <a id="TOP"/>
              <h1 class="ml-3">How to start</h1>
            </div>            
          </v-col>

          <v-col class="text-right" cols="6">
            <div v-if="isAnonymous">
                <v-btn class="ma-2" color="secondary" to="/">
                    <v-icon dark left>
                        mdi-arrow-left
                    </v-icon>
                    Back
                </v-btn>
            </div>
          </v-col>
          
        </v-row>
        <v-row><v-divider></v-divider></v-row>
        <v-row>
            <v-col cols="12">
                <div>
                    <p>
                        In this page we show you how to use the application. 
                        Each section explains in detail the steps to follow to help you having the best experience with 
                        <span class="greenops">GreenOps</span> application.
                    </p>

                    <v-card elevation="3" width="300">
                        <v-navigation-drawer width="300" floating permanent>
                            <v-list class="v-list-class" dense >
                                <v-list-item  href="#SECTION_SIGN_UP" link>
                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>1. How to sign up </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item href="#SECTION_SIGN_IN" link>
                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>2. Sign in</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item href="#SECTION_CRE_ACC" link>
                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>3. Create an Account</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item href="#SECTION_CRE_SEC" link>
                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>4. Create a Service Connection</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item href="#SECTION_RUN_AUD" link>
                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>5. Run an Audit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item href="#SECTION_AUD_REP" link>
                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>6. Generate Audit report</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item href="#SECTION_DEL_AUD" link>
                                    <v-list-item-icon>
                                        <v-icon>mdi-chevron-right</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>7. Delete Audit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-navigation-drawer>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <div>
                    <a id="SECTION_SIGN_UP"/>
                    <p><strong>1. How to sign up </strong></p>
                    <p>
                        The entry point of the application is the <span class="pagename">Sign In</span> page. 
                        It asks you to enter your credentials. 
                        The first time you use the application, you will need to create them.
                    </p>
                    <p><img src="../../assets/screenshots/help_sign_in.png" width="300px"/></p>

                    <p>
                        To do so, click on <span class="buttonlink">Sign In</span> and follow the following steps:
                    </p>
                
                    <ul class="first-level">
                        <li>
                            <p>
                                1. Click on <span class="buttonlink">Sign Up now</span>.                        
                            </p>
                            <p><img src="../../assets/screenshots/help_sign_in_up.png" width="300px"/></p>
                        </li>
                        <li>
                            <p>
                                2. In the next page, enter your email and click on <span class="buttonlink">Send Verification Code</span>.
                            </p>
                            <p><img src="../../assets/screenshots/help_send_verif_code.png" width="300px"/></p>
                        </li>
                        <li>
                            <p>
                                3. You will receive an email with the verification code.                            
                            </p>
                            <p><img src="../../assets/screenshots/help_verif_code_email.png" width="500px"/></p>
                        </li>
                        <li>
                            <p>
                                4. Enter the code and click on <span class="buttonlink">Verify Code</span>.
                            </p>
                            <p><img src="../../assets/screenshots/help_enter_verif_code.png" width="300px"/></p>
                        </li>
                        <li>
                            <p>
                                5. Once the code has been verified, you will see the email textbox darken, 
                                meaning that you can continue to enter all demanded information (password, country, name…).
                            </p>
                            <p><img src="../../assets/screenshots/help_email_verified.png" width="300px"/></p>
                        </li>
                        <li>
                            <p>
                                6. To finish you registration, click on <span class="buttonlink">Create</span>.
                            </p>
                        </li>
                    </ul> 
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <a id="SECTION_SIGN_IN"/>
                <p>
                    <strong>2. Sign in</strong>
                    <v-btn class="ml-2" icon color="secondary" href="#TOP">
                        <v-icon>
                            mdi-chevron-up
                        </v-icon>
                    </v-btn>  
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div>
                    <p>
                        You have signed up to the application. 
                        It means you have created your credentials (email/password) to use it. 
                        To start using <span class="greenops">GreenOps</span>, 
                        go to the <span class="pagename">Sign In</span> page, enter your email and password, 
                        and click on <span class="buttonlink">Sign In</span>.
                    </p>
                    <p><img src="../../assets/screenshots/help_sign_in_ok.png" width="300px"/></p>
                    <p>
                        Once you have signed in, you will be redirected to the following dialog:
                    </p>
                    <p><img src="../../assets/screenshots/help_select_account.png" width="400px"/></p>
                    <p>
                        Select an <strong>Account</strong> (see next section to know how to create an Account), 
                        and you will be redirected to the home page of <span class="greenops">GreenOps</span> application.
                    </p>
                    <p><img src="../../assets/screenshots/help_home.jpg" width="800px"/></p>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <a id="SECTION_CRE_ACC"/>
                <p>
                    <strong>3. Create an Account</strong>
                    <v-btn class="ml-2" icon color="secondary" href="#TOP">
                        <v-icon>
                            mdi-chevron-up
                        </v-icon>
                    </v-btn>  
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div>
                    <p>
                        You need an <strong>Account</strong> to be able to sign in to the <span class="greenops">GreenOps</span> application. 
                        To create an account, give a name (required) and click on <span class="buttonlink">Create</span>.
                    </p>
                    <img src="../../assets/screenshots/help_create_acc.png" width="400px"/>
                    <p>
                        The new account will be added to the list, so you can select it.
                    </p>
                    <img src="../../assets/screenshots/help_accounts.png" width="400px"/>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <a id="SECTION_CRE_SEC"/>
                <p>
                    <strong>4. Create a Service Connection</strong>
                    <v-btn class="ml-2" icon color="secondary" href="#TOP">
                        <v-icon>
                            mdi-chevron-up
                        </v-icon>
                    </v-btn>  
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div>
                    <p>
                        The first thing you need to do in order to start an Audit, is to create a service connection. 
                        It will grant <span class="greenops">GreenOps</span> application access to Azure resources you will audit. 
                        This service connection is created through a service principal that should be previously created in the Azure Portal, 
                        configured to give only READ access to the application. 
                    </p>
                    <p class="tinynote">
                        Click <a href="https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal"  target="_blank" rel="noopener noreferrer"> here</a> 
                        to visit the Azure Portal, and know how to create a service principal.
                    </p>
                    <p>
                        To create a Service Connection, follow the following steps:
                    </p>
                    <ul class="first-level">
                        <li>
                            <p>
                                1. Click on <span class="buttonlink">Settings</span> in the left menu
                            </p>
                            <p><img src="../../assets/screenshots/help_create_service_connection.png" width="800px"/></p>
                        </li>
                        <li>
                            <p>
                                2. Click on <span class="buttonlink">New Service Connection</span> (top-right corner).
                            </p>
                        </li>
                        <li>
                            <p>
                                3. You will be asked to fill-in the form as follows: select Azure as Environment, 
                                give a name to your Service Connection, 
                                and fill the remaining information using the Service Principal that you have already created 
                                through the  <a href="https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal">Azure Portal</a>. 
                                Once you have filled the form, click <span class="buttonlink">Create</span>.
                            </p>
                            <p><img src="../../assets/screenshots/help_service_connection.png" width="220px"/></p>
                        </li>
                        <li>
                            <p>
                                4. The Service Connection should be visible in the <span class="pagename">Service Connections</span> sections of the Settings page:
                            </p>
                            <p><img src="../../assets/screenshots/help_service_connections.jpg" width="700px"/></p>
                        </li>
                    </ul>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <a id="SECTION_RUN_AUD"/>
                <p>
                    <strong>5. Run an Audit</strong>
                    <v-btn class="ml-2" icon color="secondary" href="#TOP">
                        <v-icon>
                            mdi-chevron-up
                        </v-icon>
                    </v-btn>  
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div>
                    <p>
                        To run an Audit, please follow the steps below: 
                    </p>
                    <ul class="first-level">
                        <li>
                            <p>
                                1. Go to the <span class="buttonlink">Audit</span> section in the left Menu 
                                and click on <span class="buttonlink">Start New Audit</span>. 
                            </p>
                            <p><img src="../../assets/screenshots/help_new_audit.png" width="800px"/></p>
                        </li>
                        <li>
                            <p>
                                2. Give a name to the Audit, and click on <span class="buttonlink">Start Audit</span>. 
                            </p>
                            <p><img src="../../assets/screenshots/help_start_audit.png" width="700px"/></p>
                            <p>
                                You will be redirected to the page where you can follow the progress of the audit. 
                                The table shows recommendations, and during the process, each recommendation is audited according to the resources to which is applied. 
                            </p>
                            <p>
                                During the processing, the recommendation can 3 different status:
                            </p>
                            <ul class="second-level">
                                <li>
                                    <strong>Running</strong>, which means that the Audit is not finished yet for that recommendation
                                    <p><img src="../../assets/screenshots/help_status_proc.png" width="800px"/></p>
                                </li>
                                <li>
                                    <strong>Waiting for manual evaluation</strong>, which means that the recommendation requires a user intervention. 
                                    Manual evaluations are required for some recommendations, where the result depends on the user's decision.
                                    <img src="../../assets/screenshots/help_status_eval.png" width="800px"/>
                                    <p class="tinynote">
                                        (In this example, a manual evaluation is required. 
                                        At least one VM is detected, but it is up to you to determine if its SKU should be modified or not, depending on its usage)
                                    </p>
                                </li>
                                <li>
                                    <strong>Terminated</strong>, which means that a result was obtained, 
                                    indicating how many resources have triggered the recommendation, 
                                    i.e., how many resources are NOT following the recommendation in some way.
                                    <img src="../../assets/screenshots/help_status_term.png" width="800px"/>
                                    <p class="tinynote">
                                        (In this example, 4 resources are detected, and one of them is not following the recommendation)
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p>
                        The <strong>Severity</strong> is an extra indicator. 
                        It shows how important the environmental impact associated with the recommendation is.
                    </p>
                    <p>
                        If the Audit process is not finished, we are not able to finish the Audit 
                        (the <span class="buttonlink">Finish Audit</span> button is disable). 
                    </p>
                    <p><img src="../../assets/screenshots/help_finish_disable.png" width="600px"/></p>
                    <p>
                        This button stays disabled, even after the process, if there are manual interventions not resolved. 
                        Indeed, we need to manually evaluate every recommendation to be able to continue the Audit process. 
                        To do so:
                    </p>
                    <ul class="first-level">
                        <li>
                            1. Click on <span class="buttonlink">Evaluation required</span> on the recommendation 
                            (if the resources are not loaded, the link will not work)
                        </li>
                        <li>
                            2. The recommendation dialog appears so you can see the description, the concerned resources, and you can decide to:
                            <ul class="second-level">
                                <li>
                                    <strong>Ignore</strong> (no answer)
                                </li>
                                <li>
                                    <strong>Unselect </strong> (you decide to ignore the recommendation for that resource, based on some valid criteria or reasons)
                                </li>
                                <li>
                                    <strong>Select </strong> (you decide that the recommendation is valid for that resource)
                                </li>
                            </ul>
                            <img src="../../assets/screenshots/help_eval.png" width="800px"/>
                            <p>
                                You can insert a comment, and then click on <span class="buttonlink">Save</span>. Please click then on <span class="buttonlink">Finish Audit</span>. The Audit takes some seconds to finish.
                            </p>
                            <img src="../../assets/screenshots/help_comp_progress.png" width="600px"/>
                            <p>
                                Once the score calculation is retrieved from the server, it is presented in different categories, depending on the scope of the audit:
                            </p>
                            <img src="../../assets/screenshots/help_scores.png" width="700px"/>
                            <p>
                                You can also return to the Audits main page and see the history of the audits:
                            </p>
                            <img src="../../assets/screenshots/help_audit_history.png" width="800px"/>
                        </li>
                    </ul>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <a id="SECTION_AUD_REP"/>
                <p>
                    <strong>6. Generate Audit report</strong>
                    <v-btn class="ml-2" icon color="secondary" href="#TOP">
                        <v-icon>
                            mdi-chevron-up
                        </v-icon>
                    </v-btn>  
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div>
                    <p>Once the Audit finished, you can also generate and download an Excel document containing a report of the Audit. 
                       To do so, click on <span class="buttonlink">Generate Audit Report</span>:
                    </p>
                    <img src="../../assets/screenshots/help_generate_report.png" width="600px"/>
                    <p>
                        This will generate an Excel document, and, depending on the browser configuration, you can retrieve it from your PC.
                    </p>
                    <img src="../../assets/screenshots/help_excel_report.png" width="600px"/>
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <a id="SECTION_DEL_AUD"/>
                <p>
                    <strong>7. Delete Audit</strong>
                    <v-btn class="ml-2" icon color="secondary" href="#TOP">
                        <v-icon>
                            mdi-chevron-up
                        </v-icon>
                    </v-btn>  
                </p>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div>
                    <p>At any time (during the audit process, after applying the recommendations, or after the score calculation) you can delete the Audit. 
                        It will be removed from your dashboard, but not from the database: its status becomes “Abandoned” and stays for historization.
                       To do so, click on <span class="buttonlink">Delete Audit</span> at the bottom of the <span class="pagename">Audit</span> page:
                    </p>
                    <img src="../../assets/screenshots/help_delete_audit.png" width="600px"/>                    
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="text-center">
                <v-btn class="ml-2" icon medium color="secondary" href="#TOP">
                    <v-icon>
                        mdi-chevron-up
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>

      </v-container>
    </v-main>
    <Footer v-if="!isAnonymous"/>
  </v-app>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Footer from "@/components/Footer.vue";
import AccountService from "@/services/AccountService";
import useAuthStore from "@/stores/authStore";

export default {
  name: "Help",
  components: {
    Navigation,
    Footer,
  },
  setup() {
    const authStore = useAuthStore();

    return {
      authStore: authStore,
    };
  },
  data:()=>({
    accountService: null,
    isAnonymous: false
  }),
  mounted(){
    this.accountService = new AccountService(this.$route.params.accountName);
    this.isAnonymous = this.$router.history.current.path == "/help";
  }
};
</script>

<style scoped>
ul.first-level>li {
    list-style: none;
    position: relative;
}
ul.first-level>li:before {
    content: "";
    display: block;
    left: -20px;
    top: 7px;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 0 4px;
    background-color: #2987f8;
}
ul.second-level>li {
    list-style: none;
    position: relative;
}
ul.second-level>li:before {
    content: "";
    display: block;
    left: -20px;
    top: 7px;
    width: 10px;
    height: 10px;
    position: absolute;
    border-radius: 0 4px;
    background-color: #FF6D00;
}
.greenops{
    color:#1A237E;
    font-weight: bold;
}
.pagename{
    font-weight: bold;
    font-style: italic;
}
.buttonlink{
    color:#1976D2;
    font-weight: bold;
    font-style: italic;
}
.tinynote{
    font-size: 14px;
    color:#455A64;
}

.v-list-class{
    min-width: 500px;
    overflow-x: auto;
}
</style>>
